import { memo } from 'react';

import * as Styled from './external-link.styles';
import { ExternalLinkProps } from './external-link.types';

const ExternalLinkComponent = ({ label, href, target = '_blank', ...props }: ExternalLinkProps) => {
	return (
		<Styled.Wrapper href={href} target={target} {...props}>
			{label}
		</Styled.Wrapper>
	);
};

export default memo(ExternalLinkComponent);
