import styled from 'styled-components';

import { Box } from '@/shared/ui/box';

export const ScrollableBox = styled(Box)<{ fullscreen: boolean; isMobile: boolean }>`
	& > div:nth-child(2) > div:nth-child(2) {
		flex-direction: ${({ isMobile }) => isMobile && 'column'};
	}

	& iframe {
		height: ${({ fullscreen, isMobile }) =>
			isMobile ? `calc(100vh - 575px)` : `calc(100vh - ${fullscreen ? 405 : 520}px)`};
	}
`;
