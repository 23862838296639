import styled from 'styled-components';

export const Wrapper = styled.a`
	color: ${({ theme: { colors } }) => colors.primary};
	font-size: 15px;
	font-weight: 400;
	transition: ${({ theme: { transition } }) => transition.primary};

	&:visited {
		color: ${({ theme: { colors } }) => colors.primary};
	}

	&:hover {
		opacity: 0.8;
	}
`;
