import { t } from 'i18next';

import { toastActions } from '@/entities/toast';
import { userActions } from '@/entities/user';
import { userServiceApi } from '@/shared/api/user-service.api';
import { AppStoreTag } from '@/shared/constants/app-store-tag.constant';

import { ChangeUserAgreementsVariables } from '../model/types/agreements-api.interface';

const agreementsApi = userServiceApi.injectEndpoints({
	endpoints: (build) => ({
		updateUserAgreements: build.mutation<string, ChangeUserAgreementsVariables>({
			query: ({ acceptedTermsAndConditionsVersion, acceptedPrivacyPolicyVersion, dataSharing }) => {
				return {
					method: 'PATCH',
					url: 'user/update-user-agreements',
					body: {
						accepted_terms_and_conditions_version: acceptedTermsAndConditionsVersion,
						accepted_privacy_policy_version: acceptedPrivacyPolicyVersion,
						data_sharing: dataSharing,
					},
				};
			},
			async onQueryStarted({ dataSharing }, { queryFulfilled, dispatch }) {
				try {
					await queryFulfilled;
					dispatch(userActions.setDataSharing(dataSharing));
					dispatch(userServiceApi.util.invalidateTags([AppStoreTag.User]));
					dispatch(
						toastActions.addToast({
							type: 'success',
							title: t('toast.success.title'),
							message: t('agreementsSettings:toast.success.update.terms.and.conditions.message'),
						}),
					);
				} catch {}
			},
		}),
	}),
});

export const { useUpdateUserAgreementsMutation } = agreementsApi;
