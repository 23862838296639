import { isRejectedWithValue, Middleware, MiddlewareAPI } from '@reduxjs/toolkit';
import { t } from 'i18next';

import { toastActions } from '@/entities/toast';
import { DataError, NoDataError, NoDataErrorTranslation } from '@/shared/constants/no-data-error.constant';
import { StatusCodeError } from '@/shared/constants/status-code-error.constant';

export const rtkQueryErrorLogger: Middleware = (api: MiddlewareAPI) => (next) => (action) => {
	if (isRejectedWithValue(action)) {
		if (action.payload.status === StatusCodeError.MultipleChoices) {
			api.dispatch(
				toastActions.addToast({
					type: 'warning',
					title: t('toast.error.title'),
					message: t('toast.warning.reset.password'),
				}),
			);

			return next(action);
		}

		if (action.payload.status === StatusCodeError.FetchError) {
			return next(action);
		}

		if (
			action.payload.status === StatusCodeError.Unauthorized ||
			action.payload.originalStatus === StatusCodeError.Unauthorized
		) {
			api.dispatch(
				toastActions.addToast({
					message: t('toast.error.expiredToken'),
					type: 'error',
					title: t('toast.error.title'),
				}),
			);

			return next(action);
		}

		if (action.payload.status === StatusCodeError.NoData) {
			const backendMessage = action.payload.data?.detail as NoDataError;
			const backendData = action.payload.data as DataError;

			let message = '';

			if (typeof backendData === 'string' && Object.values(DataError).some((data) => backendData.startsWith(data))) {
				message = action.payload.data;
			} else if (Object.values(NoDataError).includes(backendMessage)) {
				message = t(NoDataErrorTranslation[backendMessage]);
			} else {
				message = t('toast.error.no.data.on.boat.error');
			}

			api.dispatch(
				toastActions.addToast({
					message,
					type: 'error',
					title: t('toast.error.title'),
				}),
			);
		} else {
			api.dispatch(
				toastActions.addToast({
					message:
						action.payload.data?.detail?.[0]?.msg ??
						action.payload.data?.detail?.message ??
						action.payload.data?.detail ??
						action.payload.data?.message ??
						t('toast.error.serverError'),
					type: 'error',
					title: t('toast.error.title'),
				}),
			);
		}
	}

	return next(action);
};
