import { UserContactPointType } from '../constants/user-contact-point-type.constant';
import { ContactPoint, ContactPointLabel as ContactLabel, User } from '../types/user.interface';
import { UserInitResponse } from '../types/user-api.interface';

export const ContactPointLabel: Record<UserContactPointType, ContactLabel> = {
	[UserContactPointType.Email]: 'email.notification',
	[UserContactPointType.Telegram]: 'telegram.notification',
	[UserContactPointType.InApp]: 'platform.notification',
};

export const transformUserResponse = (response: UserInitResponse): User => ({
	id: String(response.user_id),
	username: response.user_username,
	language: response.user_language,
	theme: response.user_theme,
	role: response.user_role,
	email: response.user_email,
	permissions: response.user_permissions,
	allowDashboardEdit: response.allow_dashboard_edit,
	contactPoints: response.user_contact_points?.map(
		({ is_active, type }) =>
			({
				isActive: is_active,
				label: ContactPointLabel[type],
				type,
			} as ContactPoint),
	),
	telegram: response.user_telegram_username,
	isPlant: response.is_plant,
	plantAddress: response.plant_address,
	acceptedLatestAgreements: response.accepted_latest_agreements,
	agreementsAcceptedTime: response.agreements_accepted_time,
	dataSharing: response.data_sharing,
	latestAcceptedTermsAndConditionsUrl: response.latest_terms_and_conditions_url,
	latestPrivacyPolicyUrl: response.latest_privacy_policy_url,
	isAcceptedLatestTermsAndConditions: response.is_accepted_latest_tc,
	isAcceptedLatestPrivacyPolicy: response.is_accepted_latest_pp,
});
